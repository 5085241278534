import React from 'react'

export function MainCast({ taller }) {
    return (
        <>
            <div className="container-md">
                <div className="row justify-content-end pt-4">
                    <div className="col-md-2">
                        <button
                            id="return_button"
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                                (window.location.href =
                                    'https://www.amb.cat/web/ecologia/residus/prevencio/millor-que-nou/repara/tallers-activitats')
                            }
                        >
                            Volver al listado principal
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p id="taller-subtitle">TALLER</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h3 id="taller-title-cast">{taller.talleres.nom_taller_cas}</h3>
                    </div>
                </div>
                <hr className="divider mt-5 mb-3" />
                <div className="row">
                    <div className="col-md-1">Dónde:</div>
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                        Reparat Millor que nou, calle Sepúlveda, 47, 08915, Barcelona
                    </div>
                </div>
                <hr className="divider " />
                <div className="row pt-1">
                    <div className="col-md-1">Cuándo:</div>
                    <div className="col-md-1"></div>
                    <div
                        className="col-md-5"
                        id="data_taller"
                        style={{ fontWeight: 'bold' }}
                    >
                        {`${taller.data_taller.split('-').reverse().join('.')} - ${
                            taller.hora_i
                        }-${taller.hora_f}`}
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-md-12">
                        <h4 id="taller-title2">FORMULARIO DE INSCRIPCIÓN</h4>
                    </div>
                </div>
                <hr className="divider2 mb-5" />
                <p id="recorda">Recuerda que:</p>
                <div id="recorda-list">
                    <ul id="ul-recorda-list">
                        <li>
                            Una persona no puede estar inscrita en más de 8 talleres en un
                            mismo mes.
                        </li>
                        <li>
                            Una persona no puede realizar más de dos veces un mismo taller
                            durante el cuatrimestre.
                        </li>
                        <li>
                            No presentarse a un taller sin justificar, supondrá la pérdida
                            del derecho de inscripción durante los 10 primeros días del
                            siguiente periodo de apertura a nuevas actividades.
                        </li>
                        <li>
                            Puedes consultar los tallers en los que estas apuntado, con el
                            botón "Mis talleres" que está arriba a la derecha de la
                            página.
                        </li>
                        <li>
                            Si solo puedes identificarte con el pasaporte, ven
                            presencialmente a coger cita.
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
