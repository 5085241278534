import React from 'react'

export function FooterCast() {
    return (
        <>
            <div className="container-md">
                <p id="acord" className="pt-5">
                    De acuerdo con la Ley orgánica de protección de datos de carácter personal, les informamos que sus datos serán
                    incorporados en un fichero de responsabilidad del Área Metropolitana de Barcelona (AMB) para la gestión y
                    comunicación de los talleres, cursos y servicios de Reparat Millor que nou que ha solicitado. Puede ejercer
                    sus derechos yendo a Serveis Jurídics de AMB con sede social en Carrer 62, núm. 16-18 edifici A -Zona Franca
                    08040 Barcelona
                </p>
                <hr className="divider2 mb-5" />
                <div className="row justify-content-end pt-1 pb-1">
                    <div className="col-md-2">
                        <button
                            id="return_button2"
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                                (window.location.href =
                                    'https://www.amb.cat/web/ecologia/residus/prevencio/millor-que-nou/repara/tallers-activitats')
                            }
                        >
                            Volver al listado principal
                        </button>
                    </div>
                </div>
                <hr className="divider mb-5" />
                <div className="row pb-5">
                    <div className="col-md-3 col-xs-2 pl-2">
                        <img className="amb-footer" src="../images/AMB_logo_Guia.png" alt="" />
                        <img className="amb-footer d-md-none d-md-block" src="../images/Logo_UE.jpg" alt="" />
                    </div>
                    <div className="col-md-2 col-xs-2 p-0">
                        <img className="amb-footer d-none d-sm-block" src="../images/Logo_UE.jpg" alt="" />
                    </div>
                </div>
                <div className="row pb-2">
                    <div className=" offset-md-0 col-md-12 col-xs-2 pl-2 text-center">
                        <ul className="ul-footer">
                            <li className="li-footer">
                                <a href="https://www.amb.cat/es/web/amb/com-arribar">Cómo llegar a la sede del AMB</a>
                            </li>
                            <li className="li-footer">
                                <a href="https://www.amb.cat/es/web/amb/seu-electronica/suggeriments-i-queixes">Contacto</a>
                            </li>
                            <li className="li-footer">
                                <a href="https://www.amb.cat/es/web/amb/accessibilitat">Accesibilidad</a>
                            </li>
                            <li className="li-footer">
                                <a href="https://www.amb.cat/es/web/amb/avis-legal">Avíso legal</a>
                            </li>
                            <li className="li-footer">
                                <a href="https://www.amb.cat/s/es/web/amb/politica-de-privacitat.html">Política de privacidad</a>
                            </li>
                            <li className="li-footer">
                                <a href="https://www.amb.cat/es/web/amb/premis-i-mencions">Premios y menciones</a>
                            </li>
                            <li className="li-footer" id="footer-copy">
                                {' '}
                                © Àrea Metropolitana de Barcelona
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
