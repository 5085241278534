import React from 'react'

export function FooterCat() {
    return (
        <>
            <p id="acord" className="pt-5">
                D’acord amb la Llei orgànica de protecció de dades de caràcter personal, l’informem que les seves dades seran
                incorporades en un fitxer responsabilitat de l’Àrea Metropolitana de Barcelona (AMB) per a la gestió i comunicació
                dels tallers, cursos i serveis de Reparat Millor que nou que ha sol·licitat. Pot exercir els seus drets
                adreçant-se als Serveis Jurídics de l"ÀMB amb seu social al Carrer 62, núms. 16-18 edifici A -Zona Franca 08040
                Barcelona.
            </p>
            <hr className="divider2 mb-5" />
            <div className="row justify-content-end pt-1 pb-1">
                <div className="col-md-2">
                    <button
                        id="return_button2"
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                            (window.location.href =
                                'https://www.amb.cat/web/ecologia/residus/prevencio/millor-que-nou/repara/tallers-activitats')
                        }
                    >
                        Tornar al llistat principal
                    </button>
                </div>
            </div>
            <hr className="divider mb-5" />
            <div className="row pb-5">
                <div className="col-md-3 col-xs-2 pl-2">
                    <img className="amb-footer" src="../images/AMB_logo_Guia.png" alt="" />
                    <img className="amb-footer d-md-none d-md-block" src="../images/Logo_UE.jpg" alt="" />
                </div>
                <div className="col-md-2 col-xs-2 p-0">
                    <img className="amb-footer d-none d-sm-block" src="../images/Logo_UE.jpg" alt="" />
                </div>
            </div>
            <div className="row pb-2">
                <div className=" offset-md-1 col-md-11 col-xs-2 pl-2 text-center">
                    <ul className="ul-footer">
                        <li className="li-footer">
                            <a href="https://www.amb.cat/es/web/amb/com-arribar">Com arribar a la seu d’AMB</a>
                        </li>
                        <li className="li-footer">
                            <a href="https://www.amb.cat/es/web/amb/seu-electronica/suggeriments-i-queixes">Contacte</a>
                        </li>
                        <li className="li-footer">
                            <a href="https://www.amb.cat/es/web/amb/accessibilitat">Accesibilidad</a>
                        </li>
                        <li className="li-footer">
                            <a href="https://www.amb.cat/es/web/amb/avis-legal">Avís legal</a>
                        </li>
                        <li className="li-footer">
                            <a href="https://www.amb.cat/s/es/web/amb/politica-de-privacitat.html">Política de privacitat</a>
                        </li>
                        <li className="li-footer">
                            <a href="https://www.amb.cat/es/web/amb/premis-i-mencions">Premis i mencions</a>
                        </li>
                        <li className="li-footer" id="footer-copy">
                            {' '}
                            © Àrea Metropolitana de Barcelona
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
