import React from 'react'

export function MainCat({ taller }) {
    return (
        <>
            <div className="container-md">
                <div class="row justify-content-end pt-4">
                    <div class="col-md-2">
                        <button
                            id="return_button"
                            type="button"
                            class="btn btn-primary"
                            onClick={() =>
                                (window.location.href =
                                    'https://www.amb.cat/web/ecologia/residus/prevencio/millor-que-nou/repara/tallers-activitats')
                            }
                        >
                            Tornar al llistat principal
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p id="taller-subtitle">TALLER</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h3 id="taller-title">{taller.talleres.nom_taller_cat}</h3>
                    </div>
                </div>
                <hr class="divider" />
                <div class="row">
                    <div class="col-md-1">On:</div>
                    <div class="col-md-1"></div>
                    <div class="col-md-5">
                        Reparat Millor que nou, carrer Sepúlveda, 47, 08915, Barcelona
                    </div>
                </div>
                <hr class="divider" />
                <div class="row pt-1">
                    <div class="col-md-1">Quan:</div>
                    <div class="col-md-1"></div>
                    <div class="col-md-5" id="data_taller" style={{ fontWeight: 'bold' }}>
                        {`${taller.data_taller.split('-').reverse().join('.')} - ${
                            taller.hora_i
                        }-${taller.hora_f}`}
                    </div>
                </div>
                <div class="row pt-5">
                    <div class="col-md-12">
                        <h4 id="taller-title2">FORMULARI D'INSCRIPCIÓ</h4>
                    </div>
                </div>
                <hr class="divider2 mb-5" />
                <p id="recorda">Recorda que:</p>
                <div id="recorda-list">
                    <ul id="ul-recorda-list">
                        <li>
                            Una persona no es pot inscriure a més de 8 tallers en un
                            mateix mes.
                        </li>
                        <li>
                            Una persona no pot fer més de 2 cops un mateix taller durant
                            el quatrimestre.
                        </li>
                        <li>
                            No presentar-se a un taller sense justificar suposa la pèrdua
                            del dret d'inscripció durant els primers 10 dies del següent
                            període d'obertura a noves activitats.
                        </li>
                        <li>
                            Pots consultar els tallers que t'has apuntat, amb el botó "Els
                            meus tallers" que queda dalt a la dreta d'aquesta pàgina.
                        </li>
                        <li>
                            Si només pots identificar-te amb passaport, vine
                            presencialment a agafar cita.
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
